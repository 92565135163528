import {
  getCommonParamsItemLabel,
  getGlobalParamsListItemLabel
} from '@/utils/common'

export const contentTableConfig = {
  // title: '('param.app-application-list')',
  title: '',
  propList: [
    {
      prop: 'terminalId',
      label: 'terminal.id'
    },
    {
      prop: 'sn',
      width: '160',
      label: 'terminal.sn'
    },
    {
      prop: 'tenantName',
      label: 'file.tenant'
    },
    {
      prop: 'merchantName',
      label: 'common.app-merchant-name'
    },
    {
      prop: 'modelId',
      label: 'file.model-type',
      formatter: (row) => {
        return getGlobalParamsListItemLabel(row, 'terminalModel', {
          isNeedPreFix: false
        })
      }
    },
    {
      prop: 'status',
      label: 'common.app-status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'terminal_status')
      }
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: 160
    },
    {
      label: 'common.app-operation',
      width: 500,
      slotName: 'handler'
    }
  ],
  showIndexColumn: true,
  showSelectColumn: true
}
