<template>
  <div class="remote-dialog">
    <el-dialog
      ref="dialogRef"
      width="30%"
      style="height: 80%; display: center; height: 100vh !important"
      :center="true"
      :custom-class="'custom-dialog'"
      :modelValue="visible"
      :show-close="false"
      :footer="false"
      modal
      :close-on-click-modal="false"
    >
      <!-- 头部 -->
      <template #title>
        <span class="dialog-title">Remote Screen</span>
      </template>

      <!-- 加载状态 -->
      <div class="loading-wrapper" v-show="showLoading">
        <div class="loading" id="loading-animate">
          <span></span><span></span><span></span>
        </div>
        <span class="loading-text">{{ msgText }}</span>
      </div>

      <div
        style="
          display: flex;
          justify-content: center;
          /* align-items: center; */
          height: 100%;
          margin-top: -10px;
        "
      >
        <!-- 主要内容 -->
        <div
          ref="wrapper"
          id="wrapper"
          class="content-wrapper"
          v-show="!showLoading"
        >
          <!-- 添加你的其他内容，例如挂载在背景中的图像或者其他元素 -->
          <!-- 遮罩层 -->
          <div
            ref="maskRef"
            class="canvas-mask"
            @click="handleMaskClick"
            style="
              position: absolute;
              right: 40%;
              bottom: 0;
              width: 50px;
              height: 50px;
            "
          ></div>
        </div>
      </div>
      <!-- <template v-slot:footer>
        <el-button
          size="small"
          type="danger"
          @click="handleTerminate"
          style="margin-top: 12px"
          >Terminate</el-button
        >
      </template> -->
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, defineExpose, defineProps, nextTick } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'

const SunloginControl = window.SunloginControl
const wrapper = ref(null)
const msgText = ref('Screen loading...')
const visible = ref(false)
const dialogRef = ref(null)
const store = useStore()
const maskRef = ref(null)
//  定义属性
let client = null
const showLoading = ref(true)
let view = null

let keyboardHandler = null
let mouseHandler = null
let framesCount = 0
let totalBytes = 0

const clickBtn = ref(null)
// const pollingInterval = 5000 // 轮询间隔，单位：毫秒
// const pollingLimit = 10 // 规定时间内发送的次数限制
// let pollingCount = 0 // 当前已发送的次数计数器
// let intervalId = null // 定时器ID

const props = defineProps({
  currentTerminalSession: {
    type: Object,
    required: true
  }
})

const handleMaskClick = () => {
  console.error('111111')
  // 获取画布元素
  const canvas = document.querySelector('canvas')
  console.error(canvas)
  // 获取画布上点击的位置
  const areaWidth = 100 // 区域宽度
  const areaHeight = 100 // 区域高度
  const areaRight = window.innerWidth * 0.4 // 区域右边界 x 坐标
  const areaBottom = window.innerHeight // 区域底边界 y 坐标
  const areaX = areaRight - 50 // 区域左上角 x 坐标
  const areaY = areaBottom - 50 // 区域左上角 y 坐标
  const clickX = areaX + areaWidth / 2 // 点击位置 x 坐标
  const clickY = areaY + areaHeight / 2 // 点击位置 y 坐标
  console.error(areaX, areaY, clickX, clickY)
  const ctx = canvas.getContext('2d')
  nextTick(() => {
    ctx.fillStyle = 'red' // 特定区域的背景色
    ctx.fillRect(areaX, areaY, areaWidth, areaHeight)
  })
  // 创建鼠标点击事件
  const clickEvent = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    clientX: clickX,
    clientY: clickY
  })

  // 触发画布的点击事件
  canvas.dispatchEvent(clickEvent)
}

// const reconnect = async () => {
//   try {
//     // 调用action 获取状态值
//     const res = await store.dispatch(
//       'terminal/getCurrentTerminalSession',
//       store.state.terminal.currentRemoteTerminalId
//     )
//     if (res?.data?.data?.sessionStatus === '02') {
//       // 提交mutation 更新状态值
//       store.commit('terminal/updateReconnectRemoteStatus')
//     }
//     pollingCount++ // 每次调用接口时增加计数器
//     if (pollingCount >= pollingLimit) {
//       clearInterval(intervalId) // 达到限制次数时清除定时器
//     }
//   } catch (error) {
//     clearInterval(intervalId) // 异常时 清除定时器
//     console.error('Error fetching data:', error)
//   }
// }
const amount = ref(0)

// 初始化远程客户端
const initSunLoginControl = ({ address, cid }) => {
  // 实现初始化逻辑...
  const reg = /PHSRC_(HTTP|HTTPS):\/\/([^/]+):(\d+)*\//
  const matches = address.match(reg)
  // console.info('Remote Address:' + address)
  // console.info('SessionId:' + cid)
  client = new SunloginControl.Client({
    host: matches[2],
    embed: true,
    ssl: matches[1].indexOf('HTTPS') !== -1, // Node环境下可以主动忽略证书校验（服务器没有配置也可运行）
    cid,
    port: matches[3],
    debug: false
  })

  SunloginControl.Wasm.preload().then(() => {
    // 连接客户端开始
    client
      .connect()
      .then(async () => {
        amount.value++
        console.error('获取到成功连接的数量' + amount.value)
        // 调用开始远程的监听的action 开始远程计算

        // const res = await store.dispatch('terminal/startRemoteClient', {
        //   id: store.state.terminal.currentRemoteTerminalId
        // })
        // console.log(res)
        // 更新session status
        console.log(props.currentTerminalSession)
        // await store.dispatch(
        //   'terminal/connectRemoteClient',
        //   store.state.terminal.currentTerminalSession
        // )
        await store.dispatch(
          'terminal/connectRemoteClient',
          store.state.terminal.sessionMap[amount.value]
        )
        console.error(
          '此次连接' +
            amount.value +
            '-----' +
            store.state.terminal.sessionMap[amount.value]?.id
        )
        showLoading.value = false
        visible.value = true

        // 断开连接的监听
        client.on('disconnect', async () => {
          // 调用外部的断连的action
          const res = await store.dispatch(
            'terminal/endRemoteClientConnection',
            store.state.terminal.currentRemoteTerminalId
          )
          if (wrapper.value) {
            wrapper.value.innerHTML = '' // 清空容器中的所有内容
          }
          console.log(res)
          showLoading.value = true
          visible.value = false

          // 停止截取浏览器的键盘和鼠标事件 （等同于桌面变成观看模式）
          if (keyboardHandler) {
            keyboardHandler.stop()
            keyboardHandler = null
          }

          if (mouseHandler) {
            mouseHandler.stop()
            mouseHandler = null
          }
        })

        view = new SunloginControl.Wasm(wrapper.value)
        // console.error(
        //   wrapper.value.children[0].width,
        //   wrapper.value.children[0].height
        // )

        const desktop = new SunloginControl.Plugin.Desktop({
          quality: 10
        })
        client.connectPlugin(desktop)

        // 监听桌面图像信息
        desktop.on('imageinfo', (info) => {
          console.log(info)
          view.setImageInfo(info)
          // 获取对话框的 DOM 元素
          // const dialogElement = this.$refs.dialog.$el
          nextTick(() => {
            handleMaskClick()
          })
        })
        desktop.on('screen', (count) => {
          console.log('屏幕数量' + count)
        })
        desktop.on('session', (sessions) => {
          console.log('系统会话：', sessions)
        })
        desktop.on('stream', (stream, info) => {
          framesCount = framesCount++
          totalBytes = totalBytes += info.length
          view.decode(stream, info)
          desktop.sendDecodeFinishMessage(framesCount, totalBytes)
        })
        // 传输鼠标 && 键盘事件 不传输任何主控端事件 即为观看
        // moveOutRelease 移出图像区域是自动释放鼠标/触摸

        mouseHandler = desktop.transportMouseEvent(wrapper.value)
        keyboardHandler = desktop.transportKeyBoardEvent(wrapper.value)
        const canvasElement = wrapper.value.querySelector('canvas')
        console.error(canvasElement.width, canvasElement.height)
        // setTimeout(async () => {
        //   await handleTerminate()
        // }, 2000)
        if (mouseHandler) {
          setTimeout(async () => {
            console.log(clickBtn.value)
            clickBtn.value.click()
          }, 20000)
        }
      })
      .catch(async (error) => {
        // 捕获到异常 实现重连机制 结束远程
        setTimeout(async () => {
          await handleTerminate()
        }, 2000)
        // 设置定时器，每隔一段时间调用 reconnect 函数
        // intervalId = setInterval(reconnect, pollingInterval)
        console.error('Error message:' + error)
        // client.disconnect()
        view = null
        // delete view.value
        // showLoading.value = true
        // wrapper.value = null
        ElMessage.error('error')
      })
  })
}

const openDialog = () => {
  visible.value = true
}

const endAmount = ref(0)
// 结束远程连接
const handleTerminate = async () => {
  endAmount.value++
  // 关闭对话框 销毁Dom元素

  client.disconnect()
  showLoading.value = true
  view = null
  wrapper.value = null
  visible.value = false
  if (wrapper.value) {
    wrapper.value.innerHTML = '' // 清空容器中的所有内容
  }
  // 调用Client端的disconnect方法

  // 调用action
  ElMessage.error('Connection broken...')
  await store.dispatch(
    'terminal/endRemoteClientConnection',
    store.state.terminal.currentRemoteTerminalId
  )
  console.error('结束本次连接对话' + endAmount.value)
}

defineExpose({ initSunLoginControl, openDialog })
</script>

<style scoped>
.custom-dialog {
  border-radius: 10px; /* 圆角 */
  background-color: rgba(0, 0, 0, 0.7) !important; /* 半透明黑色背景 */
  padding: 20px; /* 内边距 */
  border-radius: 10px; /* 圆角边框 */
}

.dialog-title {
  color: #333; /* 标题颜色 */
  font-size: 18px;
}

.loading-wrapper {
  margin-top: 65px;
  text-align: center;
  padding: 20px;
}

.loading {
  display: inline-block;
  width: 60px; /* 加载动画的大小 */
  height: 60px; /* 加载动画的大小 */
  position: relative;
}

.loading span {
  position: absolute;
  width: 16px; /* 加载动画元素的大小 */
  height: 16px; /* 加载动画元素的大小 */
  background: #f3214f; /* 加载动画的颜色 */
  border-radius: 50%;
  animation: loading 1.6s ease-in-out infinite;
}

.loading span:nth-child(1),
.loading span:nth-child(5) {
  top: 0;
  left: 50%;
  animation-delay: -0.8s;
}

.loading span:nth-child(2),
.loading span:nth-child(6) {
  top: 25%;
  right: 0;
  animation-delay: -0.6s;
}

.loading span:nth-child(3),
.loading span:nth-child(7) {
  top: 50%;
  right: 25%;
  animation-delay: -0.4s;
}

.loading span:nth-child(4),
.loading span:nth-child(8) {
  top: 75%;
  left: 0;
  animation-delay: -0.2s;
}

@keyframes loading {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.loading-text {
  margin-top: 10px;
  font-size: 15px;
  display: block;
  color: #666; /* 加载文本颜色 */
}

.content-wrapper {
  height: 100vh !important;
}

/* 使用样式穿透来修改 el-dialog 高度 */
.remote-dialog ::v-deep .el-dialog__body {
  height: 80vh !important;
  background-color: rgba(0, 0, 0, 0.2) !important; /* 半透明黑色背景 */
}

.remote-dialog ::v-deep .el-dialog__header {
  background-color: rgba(0, 0, 0, 0.2) !important; /* 半透明黑色背景 */
  top: -20px !important;
}

.canvas-mask {
  background-color: rgba(0, 0, 0, 0.3); /* 半透明遮罩层 */
  cursor: pointer; /* 鼠标指针样式为手型 */
  z-index: 999; /* 确保遮罩层在最上层 */
}
</style>
