<template>
  <div class="terminal-tb-container" ref="tbContainerRef">
    <!-- 左边的 el-select 元素 -->

    <!-- 右边的元素，使用 flex 容器来包裹它们 -->
    <div
      style="display: flex; justify-content: flex-end; align-items: center"
      v-if="!isViewPage"
    >
      <!-- 条件渲染的 svg-icon -->

      <!-- 条件渲染的 el-input -->
      <el-input
        v-model="inputValue"
        class="w-50 m-2"
        placeholder="Search Parameter"
        style="width: 20%"
        ><i><Search /></i
      ></el-input>
    </div>
    <!-- 表格 -->
    <el-table
      ref="tableRef"
      :data="
        parameterList?.filter(
          (data) =>
            !inputValue ||
            data.key.includes(inputValue) ||
            data.key.toLowerCase().includes(inputValue.toLowerCase())
        )
      "
      style="width: 100%; margin-top: 5px"
      @selection-change="handleSelectionChange"
      :max-height="maxHeight"
      border
      stripe
    >
      <!-- <el-table-column type="index" label="Seq" :width="50" /> -->
      <el-table-column prop="id" label="ID" width="160"> </el-table-column>
      <!-- TemplateType Template -->

      <el-table-column prop="key" label="Parameter" width="360" />
      <!-- <el-table-column prop="type" label="Value Type" width="160">
          <template #default="{ row }">
            {{ row.type + ' - ' + valueTypeMap[row.type] }}
          </template>
        </el-table-column> -->
      <el-table-column label="Value Type" width="160">
        <template #default="{ row }">
          {{ row.type + ' - ' + valueTypeMap[row.type] }}
        </template>
      </el-table-column>
      <el-table-column prop="length" label="Value Length" width="160">
        <template #default="{ row }">
          {{ row?.type !== '02' ? row.value?.length : '' }}
        </template>
      </el-table-column>

      <el-table-column prop="value" label="Value">
        <template #default="{ row }">
          <!-- 区分templateType 区分类型 -->
          <!--字符串 String-->

          <!-- 字符串转成数字类型 -->
          <div v-if="!row.isEditable">
            <span>{{ row?.value }}</span>
            <i
              v-if="!isViewPage"
              style="color: #1a6eb6; cursor: pointer"
              class="el-icon-edit-outline"
              @click="handleEdit(row)"
            ></i>
          </div>
          <el-form
            :model="row"
            inline-message
            v-if="!isViewPage"
            :ref="setFormRef(row)"
          >
            <el-form-item prop="value" :rules="getCurrentRule(row.type)">
              <el-input
                style="width: 65%"
                ref="inputRef"
                v-show="row.isEditable"
                v-model="row.value"
                @blur="row.isEditable = false"
                :disabled="row.disabled"
                maxlength="255"
              >
              </el-input>
            </el-form-item>
          </el-form>

          <!--Boolean-->
        </template>
      </el-table-column>

      <!-- Card -->
    </el-table>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  defineProps,
  defineExpose,
  nextTick,
  watch,
  reactive
} from 'vue'

import { Search } from '@element-plus/icons'
import { useCommonParam } from '@/hooks/use-common-params.js'

const props = defineProps({
  templateType: {
    type: String,
    default: '00' // Parameter
  },
  listDataOptions: {
    type: Array,
    default: () => []
  },
  isViewPage: {
    type: Boolean,
    default: false
  },
  isShowCheckItem: {
    type: Boolean,
    default: true
  },
  maxHeight: {
    type: Number,
    default: 800
  },
  ids: {
    type: Array,
    default: () => []
  },
  types: {
    type: Array,
    default: () => []
  }
})

const tableRef = ref(null)

const parameterList = ref([])
const selectedRowItems = ref([])
// const selectedRowKeys = ref([])
const getCurrentRule = (type) => {
  if (type === '01') {
    return [
      {
        required: true,
        message: 'Required'
      },
      {
        message: 'Please input an integer value',
        trigger: 'blur',
        // 假设你的校验库支持这种类型检查
        pattern: /^[-+]?\d+$/ // 正则表达式，用于匹配整数
      }
    ]
  } else if (type === '02') {
    return [
      {
        required: true,
        message: 'Required'
      },
      {
        pattern: /^(true|false)$/,
        message: 'Please input true or false',
        trigger: 'blur'
      }
    ]
  } else if (type === '00') {
    return [
      {
        required: true,
        message: 'Required'
      }
    ]
  }
}

const inputRef = ref(null)
const handleEdit = (row) => {
  console.log(row)
  parameterList.value = parameterList.value.map((item) => {
    console.log(item.id, row.id)
    if (item.id === row.id) {
      item.isEditable = true
    }
    return item
  })
  nextTick(() => {
    inputRef.value.focus()
  })
}

const formRef = ref(null)

const getValidateRes = () => {
  formRef.value.validate((valid) => {
    return valid
  })
}

const formRefs = reactive({}) // 用于存储表单引用的响应式对象

function setFormRef(row) {
  return (el) => {
    if (el) {
      formRefs[row.id] = el // 假设每行都有一个唯一的 id
    }
  }
}

async function validateAllForms() {
  const promises = []
  let result = false
  Object.keys(formRefs).forEach((id) => {
    // 使用 Object.prototype.hasOwnProperty 的正确方式
    if (Object.prototype.hasOwnProperty.call(formRefs, id)) {
      const form = formRefs[id]
      if (form && typeof form.validate === 'function') {
        promises.push(
          form.validate().catch((error) => {
            console.log(error)
            // 处理验证失败的情况（可选）
            result = false
            console.error(`Validation failed for form with id ${id}:`, error)
            return false // 或者其他适当的值来表示验证失败
          })
        )
      }
    }
  })
  try {
    const results = await Promise.all(promises)
    result = results.every((item) => item === true)
    console.log('All forms are valid', results)
  } catch (error) {
    console.error('Error validating forms', error)
  }
  return result
}

const handleSelectionChange = (val) => {
  selectedRowItems.value = val

  // 重置选中状态

  listData.value = listData.value.map((item) => {
    for (let i = 0; i < val.length; i++) {
      // 重置选中状态
      if (item?.checked) item.checked = false
      if (item.key === val[i].key) {
        return {
          ...item,
          checked: true
        }
      }
    }
    return item
  })
}

watch(
  () => selectedRowItems.value.length,
  (newVal, oldVal) => {
    if (newVal === 0 && oldVal > 0) {
      listData.value.forEach((item) => {
        if (item.checked) {
          item.checked = false
        }
      })
    }
  }
)

const inputValue = ref('')
watch(
  () => inputValue.value,
  async (newVal, oldVal) => {
    if (newVal === '' && oldVal !== '') {
      // 保存当前选中的行
      const checkedItems = listData.value
        ?.filter((item) => item.checked)
        ?.map((item) => item.key)

      nextTick(() => {
        for (let i = 0; i < parameterList.value.length; i++) {
          if (checkedItems.includes(parameterList.value[i].key)) {
            tableRef.value.toggleRowSelection(parameterList.value[i], true)
          }
        }
      })
    }
  }
)

watch(
  () => props.listDataOptions,
  (newVal) => {
    if (newVal) {
      initialListData(newVal)
      // loadCheckedItems()
    }
  }
)

const listData = ref([])

// const loadCheckedItems = () => {
//   // selectedRowKeys.value = props.listDataOptions
//   if (
//     Array.isArray(props.listDataOptions) &&
//     props.listDataOptions.length > 0 &&
//     parameterList.value.length > 0
//   ) {
//     props.listDataOptions.forEach((key) => {
//       const item = parameterList.value.find((param) => {
//         return param.key === key
//       })

//       if (item) {
//         nextTick(() => {
//           tableRef.value.toggleRowSelection(item, true)
//         })
//       }
//     })
//   }
// }

const valueTypes = ref([])
const valueTypeMap = ref({})
const cardTypeMap = ref({})
const cardTypes = ref([])
const initialListData = async (data) => {
  parameterList.value = data
  parameterList.value = Array.isArray(parameterList.value)
    ? parameterList.value?.map((item, index) => ({
        ...item,
        isEditable: false,
        id: props.ids[index],
        type: props.types[index]
      }))
    : []
  listData.value = JSON.parse(JSON.stringify(parameterList.value))
}
onMounted(async () => {
  // loadCheckedItems()
  valueTypes.value = await useCommonParam('value_type')
  valueTypes.value.forEach((item) => {
    valueTypeMap.value[item.value] = item.key
  })

  cardTypes.value = await useCommonParam('card_type')
  cardTypes.value.forEach((item) => {
    cardTypeMap.value[item.value] = item.key
  })
})

watch(
  () => props.templateType,
  async (newVal) => {
    if (newVal) {
      await initialListData(newVal)
      // loadCheckedItems()
    }
  }
)
const selectedListData = ref([])
watch(
  () => listData.value,
  (newVal) => {
    if (newVal) {
      selectedListData.value = newVal?.filter((item) => {
        if (item.checked) {
          return item.key
        }
      })
    }
  },
  { deep: true }
)

defineExpose({
  selectedRowItems,
  getValidateRes,
  selectedListData,
  parameterList,
  validateAllForms
})
</script>

<style>
/* .terminal-tb-container .el-table .el-table__cell {
  padding: 0 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
} */
</style>
